<template>
  <div class="login-background fixed-fill-parent flex flex-column flex-center">
    <div class="flex flex-center">
      <div class="login-form-container">
        <div class="login-tip flex flex-column">
          <div class="login-logo-container">
            <span style="padding-left: 10px;font-size: 18px;color: #fff;font-weight: 400;">开心联考会员学习系统</span>
          </div>
          <div class="flex-fill-parent flex flex-column flex-center">
            <div style="padding-left: 70px;">
              <img src="../assets/img/login/login-tip.png" alt="welcome to login"/>
            </div>
          </div>
        </div>
        <div class="login-form flex flex-column flex-center">
          <div>
            <li-input placeholder="手机号" v-model="loginData.phone"/>
            <li-input style="margin-top: 10px;" placeholder="密码" type="password" v-model="loginData.password"/>
            <button class="login-btn" @click="login">登录</button>
          </div>
        </div>
      </div>
    </div>
    <div style="position: absolute;bottom: 0; right: 0; left: 0; padding: 12px;text-align: center;background-color: rgba(0,0,0,.5);color: white;">
      <span>版权所有：北京慧锐博学教育科技有限公司</span>
      <a style="color: white;text-decoration: underline;padding-left: 16px;" target="_blank" href="http://www.beian.miit.gov.cn/">京ICP备20020529号-1</a>
      <span style="padding-left: 16px;"><img style="margin-right: 4px;" :src="beian"/>京公网安备 11010802032040号</span>
    </div>
  </div>
</template>

<script>
import LiInput from "@/components/input/LiInput.vue";
import beian from '../assets/beian-s.png';

export default {
  name: "login",
  components: { LiInput},
  data(){
    return {
      loginData: {
        phone: '',
        password: ''
      },
      beian
    }
  },
  methods:{
    login(){
      if(!this.loginData.phone){
        this.$message.warning('请输入手机号码');
        return;
      }

      if(!this.loginData.password){
        this.$message.warning('请输入登录密码');
        return;
      }

      let loginData = {...this.loginData};

      this.axios.post('/anon/loginByPhone', loginData).then(()=>{
        this.$message.success("登录成功");
        this.$router.push('/courseList');
      })
    }
  }
}
</script>

<style lang="less">
.login {
  &-background {
    background-image: url("../assets/img/login/loginbg.png");
  }

  &-logo{
    height: 25px;

    &-container{
      padding: 20px 39px;
    }
  }

  &-btn {
    line-height: 40px;
    background: #545451;
    border-radius: 20px;
    color: #fff;
    font-size: 16px;
    text-align: center;
    margin-top: 40px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    cursor: pointer;
    outline: none;

    &:hover{
      background-color: rgba(84,84,81,.8);
    }
  }

  &-form {
    background-color: white;
    margin-left: -16px;
    padding: 0 80px;

    &-container {
      width: 786px;
      height: 440px;
      display: flex;

      &>div {
        height: 100%;
        flex: 0 0 400px;
        border-radius: 16px;
        box-sizing: border-box;
      }
    }

  }

  &-tip {
    background-color: rgba(90,195,218,.7);
  }
}
</style>
